import _debounce from "lodash/debounce"

setTimeout(initStickyBar, 1300)

function initStickyBar() {
    const protocol = window.location.protocol
    const host = window.location.host
    const pathSegments = window.location.pathname.split("/").filter(Boolean)
    const language = pathSegments[0]
    const url = `${protocol}//${host}/${language}`

    const stickyBarClose = getCookie("sticky_bar_close")

    if (!stickyBarClose) {
        const stickyBarData = localStorage.getItem("sticky-bar")
        if (stickyBarData) {
            const {time: storedTime, loggedIn: isLoggedIn} = JSON.parse(stickyBarData)
            const authStatus = !!document.querySelector("body.logged-in")
            if (Date.now() > Date.parse(storedTime) + 24 * 60 * 60 * 1000 || isLoggedIn !== authStatus) {
                getStickyBarFunc()
            } else {
                setTimeout(renderStickyBar, 1000)
            }
        } else {
            getStickyBarFunc()
        }
    }

    async function getStickyBarFunc() {
        try {
            const response = await fetch(`${url}/get-bar`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content
                }
            })

            if (!response.ok) {
                throw new Error("Network response was not ok")
            }

            const data = await response.json()
            if (data) {
                localStorage.setItem("sticky-bar", JSON.stringify(data))
                setTimeout(renderStickyBar, 1000)
            } else {
                localStorage.removeItem("sticky-bar")
            }
        } catch (error) {
            console.error("Error fetching data:", error)
        }
    }

    function renderStickyBar() {
        const stickyBarData = localStorage.getItem("sticky-bar")
        if (stickyBarData) {
            const bar = JSON.parse(stickyBarData).bar
            const link = JSON.parse(stickyBarData).link
            const stickyBarDiv = document.createElement("div")
            stickyBarDiv.className = "sticky-bar"

            const container = document.createElement("div")
            container.className = "container"

            const row = document.createElement("div")
            row.className = "row"

            const col = document.createElement("div")
            col.className = "col-12 bar-col"

            let htmlCode = generateBarHTML(bar, link)
            col.innerHTML = htmlCode
            row.append(col)
            container.append(row)
            stickyBarDiv.append(container)
            document.querySelector(".site-header").append(stickyBarDiv)

            setupEventListeners(bar)
            showStickyBar()
        }
    }

    function generateBarHTML(bar, link) {
        return bar.giftcode
            ? link
                ? `<a href="${link}" data-id="${bar.id}" id="acceptStickyBar">${bar.locale.text} | Gutscheinecode: <span id="giftcode_sticky_bar">${bar.giftcode}</span></a>
                   <span style="margin-left: 30px; text-decoration: underline;cursor: pointer" id="giftcode_copy">Code kopieren</span>
                   <img src="/images/svg/close-cross.svg" class="close" id="closeStickyBar" alt="Schließen" />`
                : `<div data-id="${bar.id}" id="acceptStickyBar">${bar.locale.text} | Gutscheinecode: <span id="giftcode_sticky_bar">${bar.giftcode}</span></div>
                   <span style="margin-left: 30px; text-decoration: underline;cursor: pointer" id="giftcode_copy">Code kopieren</span>
                   <img src="/images/svg/close-cross.svg" class="close" id="closeStickyBar" alt="Schließen" />`
            : link
                ? `<a href="${link}" data-id="${bar.id}" id="acceptStickyBar">${bar.locale.text}</a>
                 <img src="/images/svg/close-cross.svg" class="close" id="closeStickyBar" alt="Schließen" />`
                : `<div data-id="${bar.id}" id="acceptStickyBar">${bar.locale.text}</div>
                 <img src="/images/svg/close-cross.svg" class="close" id="closeStickyBar" alt="Schließen" />`
    }

    function setupEventListeners(bar) {
        document.getElementById("giftcode_copy")?.addEventListener("click", copyGiftcodeToClipboard)

        document.getElementById("acceptStickyBar")?.addEventListener("click", (event) => {
            event.preventDefault()
            acceptStickyBarFunc(event.currentTarget.href, bar.id)
        })

        document.getElementById("closeStickyBar")?.addEventListener("click", (event) => {
            event.preventDefault()
            closeStickyBarFunc(bar.id)
        })
    }

    function copyGiftcodeToClipboard() {
        const input = document.createElement("input")
        input.style.cssText = "opacity: 0; position: absolute;"
        input.value = document.getElementById("giftcode_sticky_bar").innerText
        document.body.appendChild(input)
        input.select()
        document.execCommand("copy")
        document.getElementById("giftcode_copy").innerText = "Code in Zwischenablage kopiert"
        document.body.removeChild(input)
    }

    function showStickyBar() {
        const stickyBar = document.querySelector(".sticky-bar")
        if (stickyBar) {
            setTimeout(() => {
                stickyBar.classList.add("visibleStickyBar")
                adjustMainPadding()
                window.addEventListener("resize", _debounce(adjustMainPadding, 50), {passive: true})
            }, 500)
        }
    }

    function adjustMainPadding() {
        const barCol = document.querySelector(".bar-col")
        if (barCol) {
            document.querySelector("main").style.paddingTop = `${barCol.scrollHeight + 19}px`
        }
    }

    function acceptStickyBarFunc(href, id) {
        fetch(`${url}/increment-bar?id=${id}&type=accept`).then(() => {
            setTimeout(() => {
                window.location.replace(href)
            }, 1100)
        })
    }

    function closeStickyBarFunc(id) {
        fetch(`${url}/increment-bar?id=${id}&type=close`)
        document.cookie = `sticky_bar_close=1; expires=${new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString()}; path=/`

        const stickyBar = document.querySelector(".sticky-bar")
        if (stickyBar) {
            stickyBar.classList.remove("visibleStickyBar")
            setTimeout(() => {
                stickyBar.remove()
                document.querySelector("main").style.paddingTop = "0px"
            }, 1100)
        }
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        return parts.length === 2 ? parts.pop().split(";").shift() : null
    }
}
